import React from 'react'

interface Props {
    src: any
}

const VideoPlayer: React.FC<Props> = (props: Props) => {
    return (
        <></>
    )
}

export default VideoPlayer