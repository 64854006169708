import React from 'react'
import gojyuuonn from '../image/Aiueo.png'
import { Hr, Table } from '../../../Component/Component'
import './course.css'

const Index:React.FC = () => {
    document.title = "日文基礎"
    return (
        <div className="content">
            <h2 className="center">五十音</h2>
            <div className="center">
                <img style={{width: 600}} src={gojyuuonn} />
            </div>
            <Hr />

            <h2 className="center">動詞 - 五段動詞 / 上下段動詞</h2>
            <Table thead={["動詞類型","判斷方法","例子"]} tbody={[
                [
                    "五段動詞",
                    "非る尾\nあ行/う行/お行 +る尾",
                    "行く　飲む"
                ],
                [
                    "上/下段動詞",
                    "い行/え行 +る尾",
                    "食べる　起きる"
                ],
                [
                    "例外",
                    "漢字+る 只有這8個是上下段動詞，其他都算是五段",
                    "居る　射る　鋳る　着る　煮る　似る　干る　見る"
                ]]} />
            <Hr />
            <h2 className="center">形容詞 - イ形容詞 / ナ形容詞</h2>
            <Table thead={["形容詞類型","判斷方法","例子"]} tbody={[
                [
                    "イ形容詞",
                    "い作為結尾的形容詞",
                    "古い　温かい"
                ],
                [
                    "ナ形容詞",
                    "不是い作為結尾的形容詞",
                    "静か　好き"
                ],
                [
                    "例外",
                    "這些是ナ形容詞",
                    "綺麗な 嫌いな 得意な 有名な"
                ]
            ]} />
            <Hr />
            <h2 className="center">助詞</h2>
            <Table thead={["助詞","使用情況","例子"]} tbody={[
                [
                    "は　vs　が","は: 重點放在後面\nが: 重點放在前面","君の名前は　私**は**XXXです\n重點是你的名字 而不是誰"
                ],
                [
                    "が　vs　を","が: 動詞為自動詞\nを: 動詞為他動詞","雨が降ります　アラームを設置した\n自動詞可理解為自然發生的事 他動詞則是人為的動作"
                ],
                [
                    "に　vs　を　vs　で","に: 存在動詞 / 靜態 / 歸著點 / 有接觸\nを: 移動動詞 / 在指定的範圍內 + 沒有指定的點\nで: 動作動詞 / 動態","ここに止めてください (長い時間)\nここで止めてください (一時停止)\n公園で走ります\n公園を散歩します"
                ]
            ]} />
        </div>
    )
}

export default Index