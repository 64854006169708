import React, { useState, useEffect } from 'react'
import { Button } from '../../Component/Component'
import "./ImagePreviewPanel.css"

interface Props {
}
interface Data {
    data: objectList
}
interface objectList {
    imageList: string[]
}

const ImagePreviewPanel: React.FC<Props> = (props: Props) => {
    const [imageIndex, setImageIndex] = useState<number>(0) //to get index of image
    const [imageList, setImageList] = useState<string[]>([])
    const _setImageIndex = () => setImageIndex(imageIndex + 1)
    
    useEffect(()=>{
        (async () => {
            const response = await fetch(`/api/getImage?query={imageList (index: ${imageIndex})}`)
            if(response.ok){
                const data: Data = await response.json()
                if(data.data.imageList.length > 0) {
                    setImageList([...imageList, ...data.data.imageList])
                }
            }
        })()
    },[imageIndex])

    return (
        <div className={"image-preview-panel"}>
            { imageList.length > 0 &&
                imageList.map((x,i) => { return (
                    <div key={`image-${i}`} className={"img"}>
                        <img alt={`./i/${x}`} src={`./i/${x}`} onClick={()=>window.open(`./i/${x}`)}/>
                        <span>{x.slice(0,11)}</span>
                    </div>
                )})
            }
            <Button buttonText={"更多"} onClick={_setImageIndex}/>
        </div>
    )
}

export default ImagePreviewPanel