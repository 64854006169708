import React from 'react'
import './button.css'

interface Props {
    id?: string,
    buttonText: string,
    onClick?: (e?: Object)=>void
}

const Button: React.FC<Props> = (props) => {
    const {id, buttonText, onClick} = props
    
    return (
        <button className={"btn"} id={id} onClick={onClick}>{buttonText}</button>
    )
}

export default Button