import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './navigation.css'

const navigation = () => {
    const _faBars = faBars as IconProp
    return (
        <nav className="navigation">
            <input type="checkbox" id="show" aria-hidden="true" tabIndex={-1} className="toggle" />
            <label htmlFor="show"><FontAwesomeIcon icon={_faBars}/></label>
            <div className="nav_inner">
                <ul>
                    <li><Link to="/basic" className="group">基礎</Link></li>
                    <span> - 基礎 I</span>
                    <li><Link to="/chapter-1" className="group">第一課</Link></li>
                    <li><Link to="/chapter-2" className="group">第二課</Link></li>
                    <li><Link to="/chapter-3" className="group">第三課</Link></li>
                    <li><Link to="/chapter-4" className="group">第四課</Link></li>
                    <li><Link to="/chapter-5" className="group">第五課</Link></li>
                    <li><Link to="/chapter-6" className="group">第六課</Link></li>
                    <li><Link to="/chapter-7" className="group">第七課</Link></li>
                    <li><Link to="/chapter-8" className="group">第八課</Link></li>
                    <li><Link to="/chapter-9" className="group">第九課</Link></li>
                    <li><Link to="/chapter-10" className="group">第十課</Link></li>
                    
                    <span> - 基礎 II</span>
                    <li><Link to="/chapter-1" className="group">第十一課</Link></li>
                    <li><Link to="/chapter-2" className="group">第十二課</Link></li>
                    <li><Link to="/chapter-3" className="group">第十三課</Link></li>
                    <li><Link to="/chapter-4" className="group">第十四課</Link></li>
                    <li><Link to="/chapter-5" className="group">第十五課</Link></li>
                    <li><Link to="/chapter-6" className="group">第十六課</Link></li>
                    <li><Link to="/chapter-7" className="group">第十七課</Link></li>
                    <li><Link to="/chapter-8" className="group">第十八課</Link></li>
                    <li><Link to="/chapter-9" className="group">第十九課</Link></li>
                    <li><Link to="/chapter-10" className="group">第二十課</Link></li>
                </ul>
            </div>
        </nav>
    )
}

export default navigation