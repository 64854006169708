import React, { useEffect } from 'react'
import './PopupViewer.css'
interface Props {
    id: number,
    title: string,
    maxPage: number,
    page: number,
    nextPage: () => void,
    previousPage: () => void,
    closePanel: () => void,
}

const PopupViewer = (props: Props) => {
    const { id, title, maxPage, page, nextPage, previousPage, closePanel } = props

    const checkLeftOrRight = (e: MouseEvent) => {
        moveToNext(e.pageX > (document.documentElement.clientWidth / 2))
    }

    const moveToNext = (isNext: boolean) => {//true ? right : left
        if(isNext){
            nextPage()
        } else {
            previousPage()
        }
    }

    useEffect(()=>{
        document.title = `Page ${page}/${maxPage} - ${title}`
    },[page])

    return (
        <div className={"reader"}>
            <div className={"content"}>
                <div className={"fancy-outer"}>
                    <div className={"fancy-inner"}>
                        <div className={"fancy-content"}>

                            <img src={`./nhentai/${id}/${page}.png`}  onClick={(e: React.MouseEvent<HTMLElement>)=>{checkLeftOrRight(e as any)}}/>
                            {/* <div className="page">{title}</div> */}
                            <div className="page">Page {page} of {maxPage}</div>
                        </div>
                    </div>
                </div>
                <span className="close" onClick={closePanel}>&times;</span>
            </div>
            <div id="loading"></div>
        </div>
    )
}

export default PopupViewer