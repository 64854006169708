import React, { useState, useEffect } from 'react'
import { Button } from '../../Component/Component'
import arrow_up_svg from '../../assets/image/arrow-up.svg'
import arrow_down_svg from '../../assets/image/arrow-down.svg'
import './r18.Index.css'
import PopupViewer from './PopupViewer'

interface Data {
    data: nsfwObject
}
interface nsfwObject {
    nhentai: objectPerId[]
}
interface objectPerId {
    id: number,
    title: string,
    tags: string,
    list: string[]
}


const Main = () => {
    document.title = "Shion Maker"
    
    const [nsfwId, setNsfwId] = useState<number>(0)
    const [dataList, setDataList] = useState<objectPerId[]>([])
    const [currIndex, setCurrIndex] = useState<number>(0)
    const _setCurrIndex = () => setCurrIndex(currIndex + 1)
    const [page, setPage] = useState<number>(1)
    const [maxPage, setMaxPage] = useState<number>(Infinity)

    const _addMoreImage = async () => {
        const response = await fetch(`/api/getNsfwThumbnailImage?query={nhentai(index: ${currIndex}) {id,title,tags,list}}`)
        if(response.ok){
            const data: Data = await response.json()
            if(data.data.nhentai.length > 0){
                setDataList([...dataList, ...data.data.nhentai])
            }
        }
    }

    const _popUpViewer = (id: number) => {
        setNsfwId(id)
        setMaxPage(dataList.filter(x => x.id == id)[0].list.length)
        setPage(1)
        _gotoTop() // when change goto top
    }

    const _nextPage = () => {
        setPage(page == maxPage ? page : page + 1)
    }

    const _previousPage = () => {
        setPage(page == 1 ? page : page - 1)
    }

    const _closePanel = () => {
        setNsfwId(0)
        document.title = "Shion Maker"
    }

    const _gotoTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }

    const _gotoBottom = () => {
        window.scroll({top: document.body.scrollHeight, left: document.body.scrollWidth, behavior: 'smooth'})
    }

    useEffect(()=>{
        (async () => {
            _addMoreImage()
        })()
    }, [currIndex])

    // handle left right and esc key
    window.onkeydown = (event) => {
        const { key, repeat } = event
        if(!repeat){
            if(key == 'ArrowLeft'){
                _previousPage()
                // setIsKeyDown(true)
            } else if(key == 'ArrowRight'){
                _nextPage()
                // setIsKeyDown(false)
            } else if(key == 'Escape'){
                setNsfwId(0)
            }
        }
    }
    
    return (
        <div className={"r18"}>
            {
                nsfwId != 0 ?
                <PopupViewer title={dataList.filter(x => x.id == nsfwId)[0].title} id={nsfwId} maxPage={maxPage} page={page} nextPage={_nextPage} previousPage={_previousPage} closePanel={_closePanel}/>
                : null
            }
            <div className={"container"}>
                {dataList.length > 0 ?
                    dataList.map((x: objectPerId) => {
                        const tags = JSON.parse(x.tags)
                        return (
                            <div key={`gallery-${x.id}`} className={"gallery"} onClick={()=>_popUpViewer(x.id)}>
                                <div className={"cover"}>
                                    <img src={`./nhentai/${x.id}/${x.list[0]}`} alt={"nsfw-image"}/>
                                </div>
                                <div className={`caption
                                    ${tags.find((x: string) => x.indexOf("netorare") == 0) ? "warning-ntr" : ""}
                                    ${tags.find((x: string) => x.indexOf("yaoi")) == 0 ? "warning-yaoi" : ""}`}
                                >
                                    <span>{`[${x.id}] ` + x.title}</span>
                                </div>
                            </div>
                        )
                    })
                : null
                }
            </div>
            <Button buttonText={"更多"} onClick={_setCurrIndex}/>
            <img src={arrow_up_svg} className={"imgTopmost"} onClick={_gotoTop}/>
            <img src={arrow_down_svg} className={"imgTopmost-2"} onClick={_gotoBottom}/>
        </div>
    );
}

export default Main;
