import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faImages } from '@fortawesome/free-solid-svg-icons'
// import { faGithub, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Link } from 'react-router-dom'
const Index: React.FC = () => {
    document.title = "Shion Maker Profolio"

    const redirect: (x : string)=>void = (url) => {
        // setTimeout(()=>{
            window.location.href = url
        // }, 900)
    }

    // const setActive: (e: any)=>void = (e) => {
    //     while(e != undefined){
    //         if(e.tagName == "LI"){
    //             e.classList = 'active'
    //             break;
    //         } else {
    //             e = e.parentNode
    //         }
    //     }
    // }
    const _faImages = faImages as IconProp
    const _faTwitter = faTwitter as IconProp
    const _faGithub = faGithub as IconProp
    const _faYoutube = faYoutube as IconProp
    const _faLanguage = faLanguage as IconProp
    return (
        <div className={"Profolio"}>
            <div className={"section"}>
                <span className={"introduce"}>I'm Shion Maker</span>
                <ul>
                    <li><a onClick={(e: any)=>{redirect("https://github.com/corxsive")}}><FontAwesomeIcon icon={_faGithub} /></a></li>
                    <li><a onClick={(e: any)=>{redirect("https://twitter.com/reol_corxsive")}}><FontAwesomeIcon icon={_faTwitter} /></a></li>
                    <li><a onClick={(e: any)=>{redirect("https://www.youtube.com/channel/UCCzKRYvwDnd8y-He4QcNi3Q")}}><FontAwesomeIcon icon={_faYoutube} /></a></li>
                    <li><a onClick={(e: any)=>{redirect("https://images.noel.moe")}}><FontAwesomeIcon icon={_faImages} /></a></li>
                    <li><a onClick={(e: any)=>{redirect("https://japanese.noel.moe")}}><FontAwesomeIcon icon={_faLanguage} /></a></li>
                </ul>
            </div>
        </div>
    )
}

export default Index