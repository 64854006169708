import { Hr } from "../../../Component/Component";
import Sentence from '../Components/sentence';
import Vocab from "../Components/vocabulary";

const ch3:React.FC = () => {
    document.title = "日文 - 第三課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={[
                    "[名]‧<b>は</b>‧[数]‧<b>時から</b>‧[数]‧<b>時まで</b>です"
                ]}
                example={[
                    "10<b>時</b>20<b>分</b>です",
                    "学校<b>は</b>7<b>時から</b>4<b>時まで</b>です",
                    "今<b>は</b>"+new Date().getHours()+"<b>時</b>"+new Date().getMinutes()+"<b>分</b>です"
                ]}
                remarks={"4/7/9點時讀法要\n4時: よじ\n7時: しちじ\n9時: くじ"}
                />
            <Hr />
            <Sentence
                cases={[
                    "[時間]‧<b>に</b>‧[動]‧<b>ます</b>"
                ]}
                example={[
                    "あさ9時<b>に</b><b>起きます</b>",
                    "昨夜10時<b>に</b><b>寝ます</b>",
                    "毎日1時<b>に</b>ご飯を<b>食べます</b>"
                ]}
                remarks={"有指定時間才需要加に"}
            />
            <Hr />
            <Sentence
                cases={[
                    "[任]‧<b>ました</b>"
                ]}
                example={[
                    "私は昨日勉強し<b>ました</b>",
                    "ワンさんは一昨日遊び<b>ました</b>"
                ]}
            />
            <Hr />
            <Vocab
                data={[
                    ["一昨日","おととい","前天"],
                    ["日月火水木金土+曜日","にち　げつ　か　すい　もく　きん　ど　＋ようび","星期日~六"]
                ]}
            />
        </div>
    )
}

export default ch3