import { Hr } from "../../../Component/Component";
import Sentence from '../Components/sentence';
import Vocab from "../Components/vocabulary";

const ch5:React.FC = () => {
    document.title = "日文 - 第五課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={[
                    "~‧[名]‧<b>を</b>‧[<b>動</b>]‧ます"
                ]}
                example={[
                    "私はご飯<b>を</b><b>食べ</b>ます",
                    "ワンさんは電話<b>を</b><b>かけ</b>ます"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "~[<b>名(方法/手段)</b>]‧<b>で</b>‧[名]‧<b>を</b>‧[<b>動</b>]‧ます"
                ]}
                example={[
                    "私は<b>はし</b><b>で</b>ご飯<b>を</b><b>食べ</b>ます",
                    "<b>紙</b><b>で</b>飛行機<b>を</b><b>作り</b>ます"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "~[<b>名(地點)</b>]‧<b>で</b>‧[名]‧<b>を</b>‧[<b>動</b>]‧<b>ました</b>"
                ]}
                example={[
                    "私は<b>スーパー</b><b>で</b>魚<b>を</b><b>買い</b><b>ました</b>",
                    "<b>家</b><b>で</b>ご飯<b>を</b><b>食べ</b><b>ました</b>"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "<b>一緒に</b>‧~‧[<b>動</b>]‧<b>ませんか/ましょう</b>"
                ]}
                example={[
                    "<b>一緒に</b>テニスを<b>し</b><b>ませんか</b>",
                    "<b>一緒に</b>テニスを<b>し</b><b>ましょう</b>"
                ]}
                remarks={"邀請 與 被邀請"}
            />
            <Vocab
                data={[
                    ["寿司","すし","壽司\n寿 | 壽"],
                    ["お皿","おさら","盤子"],
                    ["八百屋","やおや","蔬果店"],
                    ["風邪","かぜ","感冒"]
                ]}
            />
        </div>
    )
}

export default ch5