import { Table } from '../../../Component/Component'

interface Props {
    data: string[][]
}

const Vocab:React.FC<Props> = (props) => {
    const { data } = props
    return (
        <div>
            <h2 className="center">生字</h2>
            <Table
                thead={["生字","讀音","注意點"]}
                tbody={data}
            />
        </div>
    )
}

export default Vocab