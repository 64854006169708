import React from 'react'
import { Title } from '../../Component/Component'
import ImagePreviewPanel from './ImagePreviewPanel'
import './index.css'

const Index: React.FC = () => {
    document.title = "Images"
    return (
        <div className={"images"}>
            <Title id={"title"} text={"Recent Upload"} />
            <ImagePreviewPanel /> 
        </div>
    )
}

export default Index