import React from 'react'
import './index.css'
import { VideoPlayer } from '../../Component/Component'

const Index: React.FC = () => {
    return (
        <VideoPlayer src={""}/>
    )
}

export default Index