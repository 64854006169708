import { Hr } from "../../../Component/Component";
import Sentence from '../Components/sentence';
import Vocab from "../Components/vocabulary";

const ch7:React.FC = () => {
    document.title = "日文 - 第七課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={[
                    ""
                ]}
                example={[
                    "",
                    "",
                    ""
                ]}
            />
            <Hr />
            <Vocab
                data={[

                ]}
            />
        </div>
    )
}

export default ch7