import React from 'react'
import './title.css'
interface Props {
    id?: string,
    text: string
}

const Title: React.FC<Props> = (props: Props) => {
    const {id, text} = props
    return (
        <div className={"title light"} id={id}>{text}</div>
    )
}

export default Title