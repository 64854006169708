import React from 'react'
import './table.css'

interface Props {
    thead: string[],
    tbody: string[][],
    className?: string,
}

const Button: React.FC<Props> = (props) => {
    const { thead, tbody, className } = props
    return (
        <table className={`styled-table ${className}`}>
            {   thead.length > 0 &&
                    <thead><tr>{thead.map((x: string) => {return <th>{x}</th>})}</tr></thead>
            }
            {   tbody.length > 0 && 
                <tbody>
                    {
                        tbody.map((row: string[]) => {
                            return <tr>
                                {
                                    row.map((col: string) => {
                                        return <td>{col}</td>
                                    })
                                }
                            </tr>
                        })
                    }
                </tbody>
            }
        </table>
    )
}

export default Button