import { Hr, Table } from '../../../Component/Component'
import Sentence from '../Components/sentence'
import Vocab from '../Components/vocabulary'

const ch1:React.FC = () => {
    document.title = "日文 - 第一課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={"[名１]‧<b>は</b>‧[名２/形]‧です"}
                example={[
                    "わたし<b>は</b>ワンです",
                    "あなた<b>は</b>キムさんです",
                    "スタンドさん<b>は</b>会社員です"
                ]}
            />
            <Hr />
            <Sentence
                cases={"[<b>これ/それ/あれ</b>]‧<b>は</b>‧[名]‧です"}
                example={[
                    "<b>これ</b><b>は</b>テレビです",
                    "<b>それ</b><b>は</b>雑誌です",
                    "<b>あれ</b><b>は</b>椅子です"
                ]}
                remarks="當問題出現これ的時候，回應時應以それ回答\n同樣問題出現それ的時候，回應時應以これ回答\nあれ則保持以あれ回應"
            />
            <Hr />
            <Sentence
                cases={"[<b>これ/それ/あれ</b>]‧<b>は</b>‧[名]‧<b>の</b>‧です"}
                example={[
                    "<b>これ</b><b>は</b><b>わたし</b><b>の</b>テレビです",
                    "<b>それ</b><b>は</b><b>わたし</b><b>の</b>です",
                    "<b>あれ</b>椅子<b>は</b><b>わたし</b><b>の</b>です"
                ]}
                remarks="の 用途為省略賓語"
            />
            <Hr />
            <Vocab data={[
                ["中国","ちゅうごく","中字有う\n国 | 國"],
                ["二十歳","はたち",""],
                ["仕事","しごと",""],
                ["雑誌","ざっし","雑 | 雜"],
                ["辞書","じしょ","書字沒有う"],
                ["携帯","けいたい","帯 | 帶"],
                ["棚","だな","(書)架"],
                ["空港","くうこう","機場"]
            ]}/>
        </div>
    )
}

export default ch1