import { Hr } from "../../../Component/Component";
import Sentence from '../Components/sentence';
import Vocab from "../Components/vocabulary";

const ch6:React.FC = () => {
    document.title = "日文 - 第六課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={[
                    "~‧[<b>形</b>]‧です"
                ]}
                example={[
                    "この本は<b>おもしろい</b>です",
                    "この川は<b>綺麗</b>です"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "~‧[<b>名１</b>]‧は‧[<b>名２</b>]‧<b>より</b>‧[<b>形</b>]‧です"
                ]}
                example={[
                    "<b>飛行機</b>は<b>船</b><b>より</b><b>早い</b>です",
                    "<b>自転車</b>は<b>バイク</b><b>より</b><b>遅い</b>です"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "~‧[<b>形１</b>]‧です。<b>そして</b>、‧[<b>形２</b>]‧です"
                ]}
                example={[
                    "この店は<b>広い</b>です。<b>そして</b>、<b>綺麗</b>です",
                    "この本は厚いです。そして、難しいです"
                ]}
            />
            <Vocab
                data={[
                    ["素敵","すてき","漂亮"],
                    ["危険な","きけんな","危險"],
                    ["危ない","あぶない","^"],
                    ["明るい","あかるい","明亮"],
                    ["東南西北","ひがし　みなみ　にし　きた","東 南 西 北"]
                ]}
            />
        </div>
    )
}

export default ch6