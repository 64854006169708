import React from 'react'
import './sentence.css'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
interface Props {
    cases: string|string[],
    example: string[],
    remarks?: string,
}

const sentence:React.FC<Props> = (props) => {
    const { cases, example, remarks } = props
    const _faExclamationCircle = faExclamationCircle as IconProp
    return (
        <div className="sentence">
            <div className="sentence-format">
                {
                    cases.constructor == Array ? 
                    cases.map((x: string, i: number) => {
                        if(i != 0){
                            return parse("<br/>" + x.replace(/<b>/g,"<strong>").replace(/<\/b>/g,"</strong>"))
                        } else {
                            return parse(x.replace(/<b>/g,"<strong>").replace(/<\/b>/g,"</strong>"))
                        }
                    })
                    :
                    parse((cases as string).replace(/<b>/g,"<strong>").replace(/<\/b>/g,"</strong>"))
                }
            </div>
            <div className="sentence-example">
                {
                    example.map((x: string, i: number) => {
                        return (<div className="sentence-example-row">{i+1}) {parse(x.replace(/<b>/g,"<strong>").replace(/<\/b>/g,"</strong>"))}</div>)
                    })
                }
            </div>
            {
                remarks != null ?
                <div className="sentence-remarks"><span className={"icon"}><FontAwesomeIcon icon={_faExclamationCircle} /></span>{parse(remarks.replace(/\\n/g,"<br/>"))}</div> : null
            }
        </div>
    )
}

export default sentence