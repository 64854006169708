import { Hr } from "../../../Component/Component";
import Sentence from '../Components/sentence';
import Vocab from "../Components/vocabulary";

const ch2:React.FC = () => {
    document.title = "日文 - 第二課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={[
                    "[<b>ここ/それ/あそこ</b>]‧<b>は</b>‧[名]‧です\n",
                    "[<b>こちら/そちら/あちら</b>]‧<b>は</b>‧[名]‧です\n"
                ]}
                example={[
                    "<b>ここ</b><b>は</b>図書館です",
                    "<b>そこ</b><b>は</b>会議室です",
                    "<b>あそこ</b><b>は</b>事務室です"
                ]}
                remarks={"當問題出現ここ的時候，回應時應以そこ回答\n同樣問題出現そこ的時候，回應時應以ここ回答\nあそこ則保持以あそこ回應"}
            />
            <Hr />
            <Sentence
                cases={"[<b>この/その/あの</b>]‧<b>は</b>‧[数]‧円です"}
                example={[
                    "<b>この</b>りんご<b>は</b>100円です",
                    "<b>その</b>ズボン<b>は</b>2500円です",
                    "<b>あの</b>テレビ<b>は</b>50000円です"
                ]}
                remarks={"詢問價錢時應用いくら\n詢問地點時應用どこ\n詢問數量時應用なん+[量]"}
            />
            <Hr />
            <Vocab data={[
                ["受付","うけつけ","詢問處"],
                ["階段","かいだん","樓層"],
                ["文房具","ぶんぼうぐ","文具"],
                ["郵便局","ゆうびんきょく","郵局"],
                ["映画館","えいがかん","電影院"],
                ["イギリス","","英國"],
                ["ドイツ","","德國"]
            ]} />
        </div>
    )
}

export default ch2