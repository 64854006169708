import { Hr } from "../../../Component/Component";
import Sentence from '../Components/sentence';
import Vocab from "../Components/vocabulary";

const ch4:React.FC = () => {
    document.title = "日文 - 第四課"
    return (
        <div className="content">
            <h2 className="center">語句</h2>
            <Sentence
                cases={[
                    "~‧[名(地點)]‧<b>へ</b>‧[<b>去(行き)/來(来)/回(帰り)</b>]‧ます"
                ]}
                example={[
                    "わたしは日本<b>へ</b><b>行き</b>ます",
                    "ワンさんは学校<b>へ</b><b>来</b>ます",
                    "山田さんは家<b>へ</b><b>帰り</b>ます"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "~‧[<b>名(方法/手段)</b>]‧<b>で</b>‧[名(地點)]‧<b>へ</b>‧[<b>去(行き)/來(来)/回(帰り)</b>]‧ます"
                ]}
                example={[
                    "わたしは<b>飛行機</b><b>で</b>日本<b>へ</b><b>行き</b>ます",
                    "ワンさんは<b>パス</b><b>で</b>学校<b>へ</b><b>来</b>ます",
                    "山田さんは<b>電車</b><b>で</b>家<b>へ</b><b>帰り</b>ます"
                ]}
            />
            <Hr />
            <Sentence
                cases={[
                    "~‧[<b>名(人)</b>]‧<b>と</b>‧[名(地點)]‧<b>へ</b>‧[<b>去(行き)/來(来)/回(帰り)</b>]‧ます"
                ]}
                example={[
                    "わたしは<b>ワンさん</b><b>と</b>日本<b>へ</b><b>行き</b>ます",
                    "ワンさんは<b>山田さん</b><b>と</b>学校<b>へ</b><b>来</b>ます",
                    "山田さんは<b>彼女</b><b>と</b>家<b>へ</b><b>帰り</b>ます"
                ]}
            />
            <Hr />
            <Vocab
                data={[
                    ["飛行機","ひこうき","飛機"],
                    ["新幹線","しんかんせん","新幹線"],
                    ["自転車","じてんしゃ","電單車(摩托車)\nてん"],
                    ["地下鉄","ちかてつ","地鐵\n鉄 | 鐵"]
                ]}
            />
        </div>
    )
}

export default ch4

