import './index.css'
import React from 'react'
import Navigation from './Components/navigation'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import Content_Basic from './course/基礎'
import { Chapter1, Chapter2, Chapter3, Chapter4, Chapter5, Chapter6, Chapter7, Chapter8, Chapter9, Chapter10 } from './course/courselist'
import Footer from './Components/footer'

const Index = () => {
    return (
        <Router>
            <div className="Japanese">
                <Navigation />
                <Routes>
                    <Route path="/" element={<Content_Basic/>}/>
                    <Route path="/basic" element={<Content_Basic />}/>
                    <Route path="/chapter-1" element={<Chapter1 />} />
                    <Route path="/chapter-2" element={<Chapter2 />} />
                    <Route path="/chapter-3" element={<Chapter3 />} />
                    <Route path="/chapter-4" element={<Chapter4 />} />
                    <Route path="/chapter-5" element={<Chapter5 />} />
                    <Route path="/chapter-6" element={<Chapter6 />} />
                    <Route path="/chapter-7" element={<Chapter7 />} />
                    <Route path="/chapter-8" element={<Chapter8 />} />
                    <Route path="/chapter-9" element={<Chapter9 />} />
                    <Route path="/chapter-10" element={<Chapter10 />} />

                </Routes>
                <Footer />
            </div>
        </Router>
    )
}

const Welcome = () => {
    return (
        <div className="content">
            <h2 className="center">請選擇課號</h2>
        </div>
    )
}

export default Index