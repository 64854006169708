import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Images from './pages/Images/Index'
import NHentai from './pages/R18-Index/r18.Index'
import Trivia from './pages/Trivia/Route'
import Profolio from './pages/Profolio/Index'
import Japanese from './pages/Japanese/Index'
import Anime from './pages/Anime/Index'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ImageEncrypt from './ImageEncrypt';

// const Subdomain = () => {
//   return (
//     process.env.NODE_ENV == 'development' ? <Images /> : // dev
//     window.location.host == 'r18.noel.moe' ? <NHentai /> :
//     // window.location.host == 'trivia.noel.moe' ? <Trivia /> : 
//     window.location.host == 'images.noel.moe' ? <Images /> : 
//     window.location.host == 'japanese.noel.moe' ? <Japanese /> :
//     window.location.host == 'anime.noel.moe' ? <Anime /> :
//     <Profolio />
//   )
// }
// ReactDOM.render(
//   <Router>
//     <Routes>
//     {/* <Route path="/i/*" element={<ImageEncrypt />}/> */}
//     <Route path="/" element={<Subdomain />}/>
//     </Routes>
//   </Router>
//   , document.getElementsByTagName('body')[0]
// )

ReactDOM.render(
  process.env.NODE_ENV == 'development' ? <Images /> : // dev
  window.location.host == 'r18.noel.moe' ? <NHentai /> :
  // window.location.host == 'trivia.noel.moe' ? <Trivia /> : 
  window.location.host == 'images.noel.moe' ? <Images /> : 
  window.location.host == 'japanese.noel.moe' ? <Japanese /> :
  window.location.host == 'anime.noel.moe' ? <Anime /> :
  <Profolio />
  , document.getElementsByTagName('body')[0]
)



reportWebVitals();